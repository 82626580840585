/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
/*@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";*/
@import "stylesheets/innospire-util-styles";
@import "stylesheets/innospire-util-stylesv2";
@import "stylesheets/colors";
@import "stylesheets/animations";
@import "stylesheets/fonts";
@import "app/hid-interactions/hid-styles";
@import "material";

html, body {
}

h1{
  font-size: 2em;
}

h2{
  font-size: 1.5em;
}

h3{
  font-size: 1.17em;
}

h4{
  font-size: 1em;
}

h5,h6,h7{
  font-size: 0.83em;
}

a.link-classic {
  color: #009bff !important;
  text-decoration: none !important;
  background-color: transparent;
}

a.link-classic:hover {
  color: rgba(0, 0, 0, .57) !important;
  text-decoration: none;
  cursor: pointer;
}

* {
  font-family: 'Lato', sans-serif;
}


.content-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  /*min-height: calc(100vh - 108px); dont do that here*/
}

.btn-outline-primary {
  box-shadow: none !important;
}

@media (max-width: 600px) {
  .modal-dialog {
    margin: 0 !important;
  }
}
.hidden {
  visibility: hidden;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

#blog-page-content {

  img {
    max-width: 100%
  }

  .mobile-banner {
    max-width: 100%;
    display: none;
  }
}

@media (max-width: 600px) {
  #blog-page-content .mobile-banner {
    display: inline-block;
  }
}


body.modal-open, body.modal-open app-root {
  overflow-y: hidden !important;
}


body {
  -webkit-overflow-scrolling: touch;
}


@media (max-width: 600px) {

  .modal-dialog {
    min-height: 100vh;
  }


  .modal-content {
    height: 100%;

    > * {
      height: 100%;
    }
  }


}




.actionlink-main-box{
  position: relative;
  min-height: 250px;
  //background: #e8e8e8;
  padding: 30px;
  margin: 50px  auto;
  max-width: 600px;
  width: 95%;
  border-radius: 5px;
  background-color: #ffffff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  h1 {
    font-size: 22px;
  }
}

.actionlink-page-wrapper{

    background-image: url('assets/img/thankyou-header.png');
    background-repeat-x: no-repeat;
    background-color: #4980ff;
    padding: 16px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    color: #4d4d4d;

}

.hid-to-ta-wrapper {
  margin-top: 4rem;
}


.no-wrap{
  white-space: nowrap;
}


.login-menu-wrapper{
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.signup-button{
  border-radius: 5px;
  background-color: #009bff;
  color: white;
  padding: 7px 20px;
  width: 100%;
  border: 0px;
  max-width: 300px;
  &:hover{
    background-color: #0075c1;
  }
  &:disabled{
    background-color: #8b8b8b;
  }
}

.signup-button-secondary{
  border-radius: 5px;
  padding: 7px 20px;
  width: 100%;
  border: 0px;
  max-width: 300px;
  font-size: 16px !important;
  font-weight: 500 !important;
  &:disabled{
    background-color: #8b8b8b;
  }
}

.login-input{
  border-radius: 5px;
  width: 100%;
  max-width: 38rem;
  margin: 5px 0px;
  border: #dbdbdb 1px solid;
  padding: 5px 10px;
  &:focus-visible{
    border: #4d4d4d 1px solid !important;
  }
}

.login-header{
  font-size: 16px;
  color: #717171;
  text-transform: capitalize;
}

.login-hint{
  font-size: 14px;
  color: #717171;
  text-transform: inherit;
  text-align: center;
  &:hover{
    text-decoration: underline;
    color: #0a8acd;
    cursor: pointer;
  }
}

.center-content{
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-click{
  pointer-events: none !important;
}


.qa-cta-button{
  margin-top: 20px !important;
  background-image: linear-gradient(to right, #fff, #fff);
  color: #009FFF !important;
  font-weight: 600 !important;
  border-radius: 100px !important;
  padding: 5px 45px !important;
  font-size: 18px !important;
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 10;
  &:hover{
    background-image: linear-gradient(to right, #4315C6, #4315C6);
    color: #fff !important;
    box-shadow: 0px 10px 20px rgba(255,255,255,0.1);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
}

.primary-qa-button{
  margin: 30px 0px !important;
  background-image: linear-gradient(to right, #0a8acd, #0a8acd);
  color: white !important;
  font-weight: 300;
  border-radius: 100px !important;
  padding: 0px 30px !important;
  font-size: 16px;
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  transition: 0.5s ease-in-out !important;
  cursor: pointer;
  z-index: 10;
  &:hover{
    background-image: linear-gradient(to right, #4315C6, #4315C6);
    color: white;
    box-shadow: 0px 10px 20px rgba(255,255,255,0.1);
    transition: 0.5s ease-in-out !important;
    cursor: pointer;
  }
  &:disabled{
    opacity: 0.6;
    filter: grayscale(100%);
    pointer-events: none;
  }
}

.qa-main-wrapper{
  max-width: 100vw;
  overflow: hidden;
}


.first-qa-category{
  background-color: #0a8acd !important;
}

.first-qa-category-gradient{
  background-image: linear-gradient(to right, #0a8acd, #49b0e1)  !important;
}

.second-qa-category{
  background-color: #FF9E00 !important;
}

.second-qa-category-gradient{
  background-image: linear-gradient(to right, #FF9E00, #c95f02)  !important;
}

.third-qa-category{
  background-color: #8200FF !important;
}

.third-qa-category-gradient{
  background-image: linear-gradient(to right, #8200FF, #5805de)  !important;
}

.fourth-qa-category{
  background-color: #2CAA28 !important;
}

.fourth-qa-category-gradient{
  background-image: linear-gradient(to right, #2CAA28, #14863e)  !important;
}

.fifth-qa-category{
  background-color: #F92A82 !important;
}

.fifth-qa-category-gradient{
  background-image: linear-gradient(to right, #F92A82, #db1835)  !important;
}

.all-qa-category{
  background-color: #313131 !important;
}

.sixth-qa-category{
  background-color: #49416D !important;
}

.sixth-qa-category-gradient{
  background-image: linear-gradient(to right, #49416D, #38315a)  !important;
}

.seventh-qa-category{
  background-color: #313131 !important;
}

.seventh-qa-category-gradient{
  background-image: linear-gradient(to right, #313131, #212121)  !important;
}

.light-italic{
  font-style: italic;
  color: #8b8b8b;
  width: 100%;
}

.info-text{
  width: 100%;
  text-align: center;
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: lighter;
}

.dialog-close-icon{
  color: #4d4d4d;
  font-size: 1.2rem;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.qa-dialog-header{
  font-size: 1.3rem;
  font-weight: bolder;
  color: #4d4d4d;
  margin: 0px;
}

.qa-secondary-dialog-button{
  border-radius: 100px !important;
  padding: 2px 20px !important;
  margin: 5px !important;
}

.delete-button{
  background-color: #A70909 !important;
  color: white !important;
  border-radius: 100px !important;
  padding: 0px 20px !important;
  margin: 5px !important;
}

.uniwunder-logo{
  max-width: 100%;
  width: 300px;
  margin: 30px;
}

.pointer-events-none{
  pointer-events: none;
}
