/*TODO should only be included in talent module!*/

button:focus {
  outline: none !important;
}

.classic-link {
  color: #ff6400 !important;
  text-decoration: underline;
}

.classic-link:hover {
  cursor: pointer;
}

/* text styles */
.text-secondary, .text-blue {
  color: #42a5f5 !important; // headyrules did that, not me - jic :D
}

.text-grey{
  color: #6e6e6e;
}
.text-green {
  color: #09b344 !important;
}

.text-red {
  color: #dc3545;
}


.text-orange {
  color: #ff9800;
}

.bg-primary {
  background-color: #42a5f5;
}

.text-primary { // headyrules did that, not me - jic :D
  color: #ff6400!important;
}

.bg-secondary {
  background-color: #ff6400;
}

.warning {
  color: #dc3545;
}

/* classic buttons */

.hid-button {
  border-style: none;
  padding: 8px 8px;
  font-size: 13px;

}

.hid-button.primary {
  color: #fff !important;
  background-color: #ff6400;
  border-radius: 5px;
  text-transform: uppercase;
}

.hid-button.primary:hover {
  background-color: #cc5000;
}

.hid-button.secondary {
  color: #fff;
  background-color: #42a5f5;
  border-radius: 5px;
  text-transform: uppercase;
}

.hid-button.secondary:hover {
  background-color: #2eb1f5;
}


.hid-button.warn {
  color: #fff !important;
  background-color: #dc3545;
  border-radius: 5px;
  text-transform: uppercase;
}

.hid-button.warn:hover {
  background-color: #dc1130;
}

.hid-button.inverse, .hid-button.outline {
  background: transparent !important; /*important to override non-inverse hovers */
  color: #000;
  text-transform: uppercase;
}

.hid-button.inverse.primary, .hid-button.outline.primary {
  color: #ff6400 !important; /*important to overwrite the hid button primary color*/
}

.hid-button.inverse.primary:hover, .hid-button.outline.primary:hover{
  color: #cc5000;
}

.hid-button.inverse.secondary, .hid-button.outline.secondary {
  color: #42a5f5;
}

.hid-button.inverse.secondary:hover, .hid-button.outline.secondary:hover {
  color: #2eb1f5;
}

.hid-button.inverse.warn, .hid-button.outline.warn {
  color: #dc3545 !important;
}

.hid-button.inverse.warn:hover, .hid-button.outline.warn:hover {
  color: #dc1130;
}

.hid-button:disabled, .hid-button[disabled] {
  /*background: #e8e8e8 !important;
  color: #777 !important;*/
  background: #e8e8e8 !important;
}

.hid-button.inverse:disabled, .hid-button.inverse[disabled] {
  /*background: #e8e8e8 !important;
  color: #777 !important;*/
  color: #777 !important;
}

.hid-button.outline.primary {
  border: 2px solid #cc5000;
}

.hid-button.outline.secondary {
  border: 2px solid #42a5f5;
}

.hid-button.outline.warning {
  border: 2px solid #dc1130;
}

.hid-button.expand {
  transition: all .2s ease;
}

.hid-button.expand:hover {
  transform: scale(1.1);
}

.link-button, .hid-link {
  font-size: 15px;
  color: #2eb1f5 !important;
  display: inline-block;
  background: none;
  border: none;
}

.link-button:hover, .hid-link:hover {
  text-decoration: underline;
  color: #0a8acd;
  cursor: pointer;
}


/* icon buttons */

.hid-icon-button {
  border-style: none;
  color: #fff;
  background: #777;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding: 0;
  position: relative;
}

.hid-icon-button > i {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hid-icon-button:hover {
  background: #5e5e5e;
}

.hid-icon-button.primary {
  background: #ff6400;
}

.hid-icon-button.primary:hover {
  background: #cc5000;
}

.hid-icon-button.secondary {
  background: #42a5f5;
}

.hid-icon-button.secondary:hover {
  background: #2eb1f5;
}

.hid-icon-button.inverse, .hid-icon-button.inverse > i {
  background: transparent;
  color: #777;
}

.hid-icon-button.inverse.primary {
  color: #ff6400 !important;
}

.hid-icon-button.inverse.secondary {
  color: #42a5f5;
}

.hid-icon-button.inverse.delete {
  color: #cc0016;
}


/* LIST */
.hid-list > li {
  padding: 8px 8px 8px 32px;
  border-bottom: 1px solid #efefef;
  width: 95%;
  transition: background-color .2s ease;
}

.hid-list > li.selectable:hover {
  background-color: #e8e8e8;
}

.hid-list {
  padding: 0;
}

i.fam {
  margin-right: 5px;
}

.hid-validationError {
  color: #dc3545;
  font-size: 12px;
}


//style reset
*, ::after, ::before {
  box-sizing: border-box;
}

.fullscreen-dialog{
  .cdk-overlay-container,.cdk-overlay-wrapper {
    z-index: 2002;
  }

 .mat-dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

}

.overflow-dialog {
  mat-dialog-container {
    overflow: unset !important;
    position: relative;
  }
}
