
/* Flex Layouting & Hiding*/
.isu-layout-column, .isu-layout-row{
  display: flex;
}

.isu-layout-column{
  flex-direction: column;
}

.isu-layout-row{
  flex-direction: row;
}

.isu-hide{
  display: none !important;
}

.isu-show{
  display: initial !important;
}

.isu-wrap{
  flex-wrap: wrap;
}

.isu-direction-start{
  justify-content: flex-start;
}

.isu-direction-end{
  justify-content: flex-end;
}

.isu-direction-center{
  justify-content: center;
}

.isu-direction-space-around{
  justify-content: space-around;
}

.isu-direction-space-between{
  justify-content: space-between;
}

.isu-perp-start{
  align-items: start;
}

.isu-perp-end{
  align-items: end;
}

.isu-perp-center{
  align-items: center;
}


@media(min-width: 1200px) {

  .isu-layout-column-lg {
    flex-direction: column;
  }

  .isu-layout-row-lg{
    flex-direction: row;
  }

  .isu-hide-lg{
    display: none !important;
  }

  .isu-show-lg{
    display: initial !important;
  }
}

@media(min-width: 900px) {
  .isu-layout-column-md {
    flex-direction: column;
  }

  .isu-layout-row-md{
    flex-direction: row;
  }

  .isu-hide-md{
    display: none !important;
  }

  .isu-show-md{
    display: initial !important;
  }
}

@media(max-width: 600px) {
  .isu-layout-column-xs {
    flex-direction: column;
  }

  .isu-layout-row-xs{
    flex-direction: row;
  }

  .isu-hide-xs{
    display: none !important;
  }

  .isu-show-xs{
    display: initial !important;
  }
}



/* Utility */

.isu-pointer:hover{
  cursor: pointer;
}

.isu-padding{
  padding: 4px;
}

.isu-padding-2x{
  padding: 8px;
}

.isu-padding-4x{
padding: 16px;
}

.isu-padding-8x{
  padding: 32px;
}


.isu-padding-inside > *{
  padding: 8px;
}

.isu-padding-inside-2x > *{
  padding: 16px;
}

.isu-padding-inside-left > * {
  padding-left: 8px;
}

.isu-padding-inside-right > * {
  padding-left: 8px;
}

.isu-padding-inside-left-2x > * {
  padding-left: 16px;
}

.isu-padding-inside-right-2x > * {
  padding-left: 16px;
}

.isu-padding-right {
  padding-right: 4px;
}

.isu-padding-right-2x {
  padding-right: 8px;
}

.isu-padding-right-4x {
  padding-right: 16px;
}

.isu-padding-right-8x {
  padding-right: 32px;
}


.isu-padding-left {
  padding-left: 8px;
}

.isu-padding-left-2x {
  padding-left: 16px;
}

.isu-padding-left-4x {
  padding-left: 32px;
}

.isu-padding-top {
  padding-top: 8px;
}

.isu-padding-top-2x {
  padding-top: 16px;
}

.isu-padding-bottom {
  padding-bottom: 8px;
}

.isu-padding-bottom-2x {
  padding-bottom: 16px;
}


.isu-margin {
  margin: 8px;
}

.isu-margin-2x {
  margin: 16px;
}

.isu-margin-right {
  margin-right: 8px;
}

.isu-margin-right-2x {
  margin-right: 16px;
}

.isu-margin-left {
  margin-left: 8px;
}

.isu-margin-left-2x {
  margin-left: 16px;
}


.isu-margin-top {
  margin-top: 8px;
}

.isu-margin-top-2x {
  margin-top: 16px;
}

.isu-margin-bottom {
  margin-bottom: 8px;
}

.isu-margin-bottom-2x {
  margin-bottom: 16px;
}

.isu-margin-top-4x {
  margin-top: 32px;
}

.isu-margin-bottom-4x {
  margin-bottom: 32px;
}


/*Dummy Helper */
.isu-color-white{
  color: #fff;
}

.isu-color-blue {
  color: #42a5f5;
}

.isu-color-green {
  color: #008000;
}

.isu-color-magenta {
  color: #dd3264;
}

.isu-color-orange {
  color: #ff6400;
}

.isu-color-grey {
  color: #969ba0;
}

.isu-color-lightgrey {
  color: rgb(51, 51, 51);
}

/*Background-color */

.isu-background-white{
  background: #fff;
}

.isu-background-blue {
  background: #42a5f5;
}

.isu-background-green {
  background: #008000;
}

.isu-background-magenta {
  background: #dd3264;
}

.isu-background-orange {
  background: #ff6400;
}

.isu-background-grey {
  background: #969ba0;
}

.isu-color-lightgrey {
  color: rgb(51, 51, 51);
}


/*other styles */
.isu-box {
  border: 1px solid #c5c5c5;
  background-color: #fafafa;
  padding: 15px;
}

.isu-cta-button{
  background: #ff6400;
  color: #fff;
  margin-top: 20px;
  border: none;
  border-radius: 7px;
  width: 250px;
  font-size: 14px;
  font-weight: 900;
  padding: .5rem 0.5rem;
  border-top: 1px solid #e6e5e5;

  &:hover {
    cursor: pointer;
    background-color: #cc5000;
  }
}


