// Lato Font

@font-face {
  font-family: 'Lato';
  src: url("../assets/font/Lato/Lato-Regular.woff2") format('woff2');
  src: url('../assets/font/Lato/Lato-Regular.woff') format('woff');
  src: url('../assets/font/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url("../assets/font/Lato/Lato-Bold.woff2") format('woff2');
  src: url('../assets/font/Lato/Lato-Bold.woff') format('woff');
  src: url('../assets/font/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face{
  font-family: 'Lato-Black';
  src: url('../assets/font/Lato/Lato-Black.ttf') format('truetype');
}
@font-face{
  font-family: 'Lato-BlackItalic';
  src: url('../assets/font/Lato/Lato-BlackItalic.ttf') format('truetype');
}
@font-face{
  font-family: 'Lato-BoldItalic';
  src: url('../assets/font/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face{
  font-family: 'Lato-Italic';
  src: url('../assets/font/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face{
  font-family: 'Lato-Light';
  src: url('../assets/font/Lato/Lato-Light.ttf') format('truetype');
}

@font-face{
  font-family: 'Lato-LightItalic';
  src: url('../assets/font/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face{
  font-family: 'Lato-Thin';
  src: url('../assets/font/Lato/Lato-Thin.ttf') format('truetype');
}

@font-face{
  font-family: 'Lato-ThinItalic';
  src: url('../assets/font/Lato/Lato-ThinItalic.ttf') format('truetype');
}

//Montserrat Font

//weight 100
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url("../assets/font/Montserrat/static/Montserrat-Thin.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  src: url("../assets/font/Montserrat/static/Montserrat-ThinItalic.ttf");
}

//weight 200
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url("../assets/font/Montserrat/static/Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  src: url("../assets/font/Montserrat/static/Montserrat-ExtraLightItalic.ttf");
}

//weight 300
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url("../assets/font/Montserrat/static/Montserrat-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  src: url("../assets/font/Montserrat/static/Montserrat-LightItalic.ttf");
}

//weight 400
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/Montserrat/static/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  src: url("../assets/font/Montserrat/static/Montserrat-Italic.ttf");
}

//weight 500
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("../assets/font/Montserrat/static/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  src: url("../assets/font/Montserrat/static/Montserrat-MediumItalic.ttf");
}

//weight 600
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("../assets/font/Montserrat/static/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  src: url("../assets/font/Montserrat/static/Montserrat-SemiBoldItalic.ttf");
}

//weight 700
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/font/Montserrat/static/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  src: url("../assets/font/Montserrat/static/Montserrat-BoldItalic.ttf");
}

//weight 800
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/font/Montserrat/static/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  src: url("../assets/font/Montserrat/static/Montserrat-ExtraBoldItalic.ttf");
}

//weight 900
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("../assets/font/Montserrat/static/Montserrat-Black.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 900;
  src: url("../assets/font/Montserrat/static/Montserrat-BlackItalic.ttf");
}


// Poppins Font

//weight 100
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 100;
  src: url("../assets/font/Poppins/Poppins-Thin.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  src: url("../assets/font/Poppins/Poppins-ThinItalic.ttf");
}

//weight 200
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  src: url("../assets/font/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  src: url("../assets/font/Poppins/Poppins-ExtraLightItalic.ttf");
}

//weight 300
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: url("../assets/font/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  src: url("../assets/font/Poppins/Poppins-LightItalic.ttf");
}

//weight 400
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  src: url("../assets/font/Poppins/Poppins-Italic.ttf");
}

//weight 500
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url("../assets/font/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  src: url("../assets/font/Poppins/Poppins-MediumItalic.ttf");
}

//weight 600
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url("../assets/font/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: url("../assets/font/Poppins/Poppins-SemiBoldItalic.ttf");
}

//weight 700
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/font/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  src: url("../assets/font/Poppins/Poppins-BoldItalic.ttf");
}

//weight 800
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/font/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: url("../assets/font/Poppins/Poppins-ExtraBoldItalic.ttf");
}

//weight 900
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: url("../assets/font/Poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  src: url("../assets/font/Poppins/Poppins-BlackItalic.ttf");
}
