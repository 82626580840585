/*TODO split marketing utils from dev utils */
/*DO NOT USE ANYMORE! BEFORE KILLING IT; CHECK WHICH STYLES ARE NEEDED IN THE APPLICATIONS CODE AND CONSIDER EXISTING CONTENT ON HID*/
a:hover {
  color: rgba(0, 0, 0, .57) !important;
  text-decoration: none;
  cursor: pointer;
}

.pointer:hover{
  cursor: pointer;
}

/* Flex Layouting*/
.isu-layout-column, .isu-layout-row{
  display: flex;
}

.isu-layout-column{
  flex-direction: column;
}

.isu-layout-row{
  flex-direction: row;
}

.isu-hide{
  display: none !important;
}

.isu-show{
  display: initial !important;
}

.isu-wrap{
  flex-wrap: wrap;
}

.isu-direction-start{
  justify-content: flex-start;
}

.isu-direction-end{
  justify-content: flex-end;
}

.isu-direction-center{
  justify-content: center;
}

.isu-direction-space-around{
  justify-content: space-around;
}

.isu-direction-space-between{
  justify-content: space-between;
}

.isu-perp-start{
  align-items: start;
}

.isu-perp-end{
  align-items: end;
}

.isu-perp-center{
  align-items: center;
}


@media(min-width: 1200px) {

  .isu-layout-column-lg {
    flex-direction: column;
  }

  .isu-layout-row-lg{
    flex-direction: row;
  }
  
  .isu-hide-lg{
    display: none !important;
  }

  .isu-show-lg{
    display: initial !important;
  }
}

@media(min-width: 900px) {
  .isu-layout-column-md {
    flex-direction: column;
  }

  .isu-layout-row-md{
    flex-direction: row;
  }

  .isu-hide-md{
    display: none !important;
  }

  .isu-show-md{
    display: initial !important;
  }
}

@media(max-width: 600px) {
  .isu-layout-column-xs {
    flex-direction: column;
  }

  .isu-layout-row-xs{
    flex-direction: row;
  }

  .isu-hide-xs{
    display: none !important;
  }

  .isu-show-xs{
    display: initial !important;
  }
}

/* Display Elements */

.isu-position-fixed {
  position: absolute;
}

.isu-position-relative {
  position: relative;
}

.isu-display-none {
  display: none;
}

.isu-display-true {
  display: block;
}


.isu-display-hide {
  display: none !important;
}

.isu-display-show {
  display: block !important;
}

@media(max-width: 1200px) {

  .isu-display-1200-none {
    display: none;
  }

  .isu-display-1200-true {
    display: block;
  }
}

@media(max-width: 900px) {

  .isu-display-900-none {
    display: none;
  }

  .isu-display-900-true {
    display: block;
  }

}

@media(max-width: 600px) {

  .isu-display-600-none {
    display: none;
  }

  .isu-display-600-true {
    display: block;
  }

}

@media(max-width: 1200px) {

  .isu-display-xl-hide {
    display: none !important;
  }
  .isu-display-xl-show {
    display: block !important;
  }
}

@media(max-width: 992px) {

  .isu-display-lg-hide {
    display: none !important;
  }

  .isu-display-lg-show {
    display: block !important;
  }
}

@media(max-width: 768px) {

  .isu-display-md-hide {
    display: none !important;
  }

  .isu-display-md-show {
    display: block !important;
  }

}

@media(max-width: 576px) {

  .isu-display-sm-hide {
    display: none !important;
  }

  .isu-display-sm-show {
    display: block !important;
  }

}


/* Utility */

.isu-padding-0-5x {
  padding: 4px;
}

.isu-padding{
  padding: 8px;
}

.isu-padding-2x{
  padding: 16px;
}

.isu-padding-inside > *{
  padding: 8px;
}

.isu-padding-inside-2x > *{
  padding: 16px;
}

.isu-padding-inside-left > * {
  padding-left: 8px;
}

.isu-padding-inside-right > * {
  padding-left: 8px;
}

.isu-padding-inside-left-2x > * {
  padding-left: 16px;
}

.isu-padding-inside-right-2x > * {
  padding-left: 16px;
}

.isu-padding-right {
  padding-right: 8px;
}

.isu-padding-right-2x {
  padding-right: 16px;
}

.isu-padding-right-4x {
  padding-right: 32px;
}

.isu-padding-left {
  padding-left: 8px;
}

.isu-padding-left-2x {
  padding-left: 16px;
}

.isu-padding-left-4x {
  padding-left: 32px;
}

.isu-padding-top {
  padding-top: 8px;
}

.isu-padding-top-2x {
  padding-top: 16px;
}

.isu-padding-bottom {
  padding-bottom: 8px;
}

.isu-padding-bottom-2x {
  padding-bottom: 16px;
}


.isu-margin {
  margin: 8px;
}

.isu-margin-2x {
  margin: 16px;
}

.isu-margin-right {
  margin-right: 8px;
}

.isu-margin-right-2x {
  margin-right: 16px;
}

.isu-margin-left {
  margin-left: 8px;
}

.isu-margin-left-2x {
  margin-left: 16px;
}


.isu-margin-top {
  margin-top: 8px;
}

.isu-margin-top-2x {
  margin-top: 16px;
}

.isu-margin-bottom {
  margin-bottom: 8px;
}

.isu-margin-bottom-2x {
  margin-bottom: 16px;
}

.isu-margin-top-4x {
  margin-top: 32px;
}

.isu-margin-bottom-4x {
  margin-bottom: 32px;
}


/*Dummy Helper */
.isu-color-white{
  color: #fff;
}

.isu-color-blue {
  color: #009bff;
}

.isu-color-green {
  color: green;
}

.isu-color-magenta {
  color: #dd3264;
}

.isu-color-orange {
  color: #ff6400;
}

.isu-color-grey {
  color: #969ba0;
}

.isu-color-lightdark {
  color: rgb(51, 51, 51);
}

/*Background-color */

.isu-background-color-lightblue {
  background-color: #5fc3f7;
}

.isu-background-color-darkblue {
  background-color: #65ace0;
}

.isu-background-color-lightgrey {
  background-color: #f2f2f2;
}

/*offer page content styles */
#page-content{
  h1 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 20px;
    margin-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
  }
}

/*Text styles */

.isu-text-bold {
  font-weight: 700;
}

.isu-text-center {
  text-align: center;
}

.isu-text-fontsize-14 {
  font-size: 14px;
}

.isu-text-fontsize-15 {
  font-size: 15px;
}

.isu-text-fontsize-22 {
  font-size: 22px;
}

.isu-text-fontsize-0-9rem {
  font-size: 0.9rem;
}

.isu-text-fontsize-0-8rem {
  font-size: 0.8rem;
}


.isu-text-linehight-1-6 {
  line-height: 1.6;
}


/*other styles */

.isu-offer-image {
  max-width: 100%;
  height: auto;
}

.isu-icon-wrapper {
  min-width: 50px;
  text-align: center
}

.isu-icon-wrapper-small {
  min-width: 30px;
  text-align: center
}

.isu-text-wrapper {
  max-width: 600px;
}

.isu-width-max-670 {
  max-width: 670px;
}

.isu-box {
  border: 1px solid #c5c5c5;
  background-color: #fafafa;
  padding: 15px;
}

.isu-places-available-959-hide {
  @media (max-width: 959px) {
    display: none !important;
  }
}

.isu-places-available-959-show {
  @media (max-width: 959px) {
    display: block !important;
  }
}

.isu-places-available-774-hide {
  @media (max-width: 774px) {
    display: none !important;
  }
}

.isu-places-available-774-show {
  @media (max-width: 774px) {
    display: block !important;
  }
}


#page-content {

  .steuerseminar-mobile {

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 15px;
      color: #969ba0;
    }
  }

}

.isu-image-places-available {
  position: absolute;
  top: 75px;
  left: 500px;
}


@media (max-width: 959px) {
  .isu-mobile-margin-bottom {
    margin-bottom: 65px;
  }
}
